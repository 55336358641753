export const modalTabNavigationMixin = {
    methods: {
        changeStep(tabId) {
            this.activeTab = tabId
        },
        previousTab() {
            const currentIndex = this.navigationTabs.findIndex(tab => tab.id === this.activeTab)
            if (currentIndex > 0) {
                this.activeTab = this.navigationTabs[currentIndex - 1].id
            }
        },
        nextTab() {
            const currentIndex = this.navigationTabs.findIndex(tab => tab.id === this.activeTab)
            if (currentIndex < this.navigationTabs.length - 1) {
                this.activeTab = this.navigationTabs[currentIndex + 1].id
            }
        },
        disableAllButFirstTab(navigationTabs) {
			for (let i = 1; i < navigationTabs.length; i+=1) {
				navigationTabs[i].isDisabled = true
			}
		},
		clearTabsValidation() {
			this.navigationTabs.forEach(tab => {
				tab.hasErrors = false
			})
		}
    }
}

export default modalTabNavigationMixin 