<template>
    <div>
        <recess-table-flex 
            class="qa-table-assorted-product-list col-12" 
            :table-options="setTableOptions"
            :pagination-options="setPaginationOptions"
            @getData="updateList"
        >       
            <recess-table-flex-head>
                <recess-table-flex-row>
                    <recess-table-flex-head-item
                        v-for="header in headers"
                        :key="header.key"
                        :cell-size="header.cellSize"
                        :class="header.cssClass"
                    >
                        <p class="m-0">{{ header.title }}</p>
                    </recess-table-flex-head-item>
                </recess-table-flex-row>
            </recess-table-flex-head>

            <recess-table-flex-body>
                <recess-table-flex-row v-for="(course, index) in courses"
                    :key="index"
                >
                    <recess-table-flex-cell :data-label="headers[0].title"
                        :class="`qa-incompany-course-selection-${index}`"
                        cell-size="narrow"
                    >
                        <recess-checkbox-input
                            name="select-one-course"
                            :class="`qa-select-one-course-${index}`"
                            :default-checked="course.isChecked"
                            variant="secondary"
                            @input="() => selectCourse(course, index)"
                        />
                    </recess-table-flex-cell>
                    <recess-table-flex-cell :data-label="headers[1].title"
                        :class="`qa-incompany-course-external-id-${index}`"
                    >
                        {{ course.externalId }}
                    </recess-table-flex-cell>
                    <recess-table-flex-cell :data-label="headers[2].title"
                        :class="`qa-incompany-course-name-${index}`"
                    >
                        {{ course.name }}
                    </recess-table-flex-cell>
                </recess-table-flex-row>
            </recess-table-flex-body>
        </recess-table-flex>
    </div>
</template>

<script>
// Constants and validations
import { API_CALL_URL_PATHS, PAGINATION } from '@/../../shared/constants/constantsGeneral.json'

// Apis
import { getItemsFromSearchIndex } from '@/../../shared/api/SharedClient'
import { PROVIDER } from '@/constants/EdumsProviderConstants.json'

// Mixins
import tableMixin from '@/../../shared/mixins/tableMixin' 

export default {
  name: 'IncompanyPurchaseAgreementLegalCourseList',
  mixins: [tableMixin],
  props: {
    accountId: {
        type: String,
        default: ''
    },
    setSelectedFilters: {
        type: Object,
        default: () => {
            return {}
        }
    },
    selectedCourse: {
        type: Object,
        default: () => {
            return {}
        }
    }
  },
  data() {
    const paginationOptions = [
        { displayText: '5', value: 5 },
        { displayText: '10', value: 10 }
    ]
    return {
      PROVIDER,
      PAGINATION,
      API_CALL_URL_PATHS,
      headers: [
        {   key: 'selectedStatus',
            title: '',
            cellSize: 'narrow',
            cssClass: 'mr-4 pr-1'
        },
        {
            key: 'externalId',
            title: PROVIDER.Order.Shared.Popups.AcceptRejectOrder.AcceptOrder.IncompanyPurchaseAgreement.CourseTab.Table.Headers.ExternalId
        },
        {
            key: 'title',
            title: PROVIDER.Order.Shared.Popups.AcceptRejectOrder.AcceptOrder.IncompanyPurchaseAgreement.CourseTab.Table.Headers.Course
        }
        ],
        setTableOptions: {
            title:'',
            setCellSizes: true 
        },
        setPaginationOptions: { 
            showTopPagination: false, 
            showBottomPagination: true, 
            currentPage: this.$route.query.page !== undefined ? Math.round(Number(this.$route.query.page)) : 1,
            totalCount: null,
            selectOptions: paginationOptions,
            defaultTotalItemsPerPage: paginationOptions[0].value,
            itemsPerPageFromUrlQuery: this.$route.query.itemsPerPage !== undefined ? Number(this.$route.query.itemsPerPage) : paginationOptions[0].value,
            showSelectOption: true, 
            selectOptionsLabel: PAGINATION.selectOptionsLabel,
            paginationShowLabel: PAGINATION.paginationShowLabel,
            paginationOfLabel: PAGINATION.paginationOfLabel,
            scroll: false 
        },
        filter: {
            top: 0,
            skip: 0,
            orderBy: ['externalId'],
            filter: `(deleted eq false and productType eq 'Incompany' and targetAccounts/any(a: a eq '${this.accountId}') and purchaseAgreementReference eq '')`,
            searchMode: 'all'
		},
        courses: []
    }
  },
  methods: {
    async getCourses() {
        const filter = JSON.parse(JSON.stringify(this.filter))
        try {
            const response = await getItemsFromSearchIndex(
                process.env.VUE_APP_PROVIDER_API_URL,
                'Courses',
                API_CALL_URL_PATHS.search,
                filter
            )

            if (!response) return

            this.courses = response.results.map(result => ({
                ...result.document,
                isChecked: this.selectedCourse ? result.document.id === this.selectedCourse.id : false
            }))

            this.setPaginationOptions.totalCount = response.count
        } 
        catch (error) {
            console.error('Something went wrong while retrieving the courses', error)
        }
    },
    selectCourse(selectedCourse, selectedIndex) {
      const isSelected = this.courses[selectedIndex].isChecked
      
      this.courses.forEach(course => {
        course.isChecked = false
      })

      if (!isSelected) {
        this.courses[selectedIndex].isChecked = true
        this.$emit('setSelectedCourse', selectedCourse)
      } else {
        this.$emit('setSelectedCourse', null)
      }
    },
    getSelectedSearch() {
		this.filter.search = this.setSelectedFilters.searchQuery
    },
    updateList({ currentPage, itemsPerPage }) {

        this.filter.search = ''
        this.setPaginationOptions.itemsPerPageFromUrlQuery = itemsPerPage
        this.setPaginationOptions.currentPage = currentPage

        this.filter.top = itemsPerPage
        this.filter.skip = currentPage * itemsPerPage - itemsPerPage
        
        this.getSelectedSearch()
        this.getCourses()
    }
  }
}
</script>