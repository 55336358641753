<template>
	<recess-modal :show-modal="isModalVisible"
		@close="close"
	>
		<template slot="modal-title">
			<modal-tab-navigation
				:navigation-tabs="navigationTabs"
				:active-tab="activeTab"
				@tab-selected="updateStep"
			/>
			<h3 class="mt-5">{{ PROVIDER.Order.Shared.Popups.AcceptRejectOrder.AcceptOrder.Title }} {{ orderNumber }}</h3>
		</template>

		<template slot="modal-body">
			<!-- Tab 1 - Legal Company Selection -->
			<incompany-purchase-agreement-legal-company
				v-if="activeTab === 'handelsnamen'"
				ref="legalCompany"
				:provider-id="providerId"
				:is-admin="isAdministrator"
				:legal-company-options="legalCompanyOptions"
				:legal-company-list="legalCompanyList"
				:legal-company-id="selectedLegalCompanyId"
				:details="legalCompanyDetails"
				@setLegalCompanyId="setLegalCompanyId"
				@isLegalCompanyCreditNumberValid="handleCreditNumberValidation"
				@isLegalCompanyVatNumberValid="handleVatNumberValidation"
				@setLegalCompanyDetails="setLegalCompanyDetails"
			/>
			<!-- Tab 2 - Course Selection -->
			<div v-if="activeTab === 'leeraanbod'">
				<p class="mb-4">
					{{ PROVIDER.Order.Shared.Popups.AcceptRejectOrder.AcceptOrder.IncompanyPurchaseAgreement.CourseTab.Title }}
					<span class="u-text-underline">{{ PROVIDER.Order.Shared.Popups.AcceptRejectOrder.AcceptOrder.IncompanyPurchaseAgreement.CourseTab.TitlePartTwo }}</span>
				</p>
				<recess-search-input
					v-model.trim="selectedFilters.searchQuery"
					:placeholder="PROVIDER.Order.Shared.Popups.AcceptRejectOrder.AcceptOrder.IncompanyPurchaseAgreement.CourseTab.SearchPlaceholder"
					class="qa-incompany-course-search w-50"
					@input="(newValue) => setSpecificFilter('searchQuery', newValue)"
				/>
				<div v-if="selectedCourse"
					class="d-flex mt-4"
				>
					<p class="u-text-bold">{{ PROVIDER.Order.Shared.Popups.AcceptRejectOrder.AcceptOrder.IncompanyPurchaseAgreement.CourseTab.SelectedCourse }}</p>
					<p class="ml-2 qa-selected-course">{{ selectedCourse.name }}</p>
				</div>
				<incompany-purchase-agreement-course-list
					ref="course"
					:set-selected-filters="localFilters"
					:account-id="accountId"
					:selected-course="selectedCourse"
					@setSelectedCourse="setSelectedCourse"
				/>
			</div>
			<!-- Tab 3 - Financial Details -->
			<incompany-purchase-agreement-finance
				v-if="activeTab === 'prijs'"
				ref="finance"
				:countries="countryOptions"
				:selected-country="selectedCountry"
				:vat-percentage-options="vatPercentageOptions"
				:selected-vat-percentage="selectedVatPercentage"
				:sub-total-without-vat="order.orderLines[0].subtotalWithoutVAT"
				:vat-amount="vatAmount"
				:invoice-items="currentInvoiceItems"
				@setCountry="setCountry"
				@setVatPercentage="setVatPercentage"
				@setInvoiceItemCostType="setInvoiceItemCostType"
				@setInvoiceItemVatPercentage="setInvoiceItemVatPercentage"
				@setInvoiceItemPriceBeforeTax="setInvoiceItemPriceBeforeTax"
				@perPersonOrderCosts="setPerPersonOrderCosts"
				@addInvoiceItem="addInvoiceItem"
				@removeInvoiceItem="removeInvoiceItem"
				@performCalculations="performCalculations"
				@setInvoiceItemCountryAndVatPercentageOptions="setInvoiceItemCountryAndVatPercentageOptions"
			/>
		</template>

		<template slot="modal-footer">
			<div class="d-flex justify-content-end">
				<recess-button variant="tertiary"
					:title="BUTTON_TEXT.cancel"
					class="qa-order-cancel-button"
					@click.native.prevent="close()"
				/>
				<recess-button v-if="activeTab !== navigationTabs[0].id"
					variant="secondary"
					:title="BUTTON_TEXT.previousTab"
					class="ml-3 qa-order-go-back-button"
					@click.native.prevent="goBack()"
				/>
				<recess-button
					variant="secondary"
					type="submit"
					:title="activeTab !== navigationTabs[navigationTabs.length - 1].id ? BUTTON_TEXT.nextTab : BUTTON_TEXT.submit"
					class="ml-3 qa-order-submit-button"
					@click.native.prevent="submitHandler()"
				/>
			</div>
		</template>
	</recess-modal>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

// Apis
import { postItem, patchItem, getItems, getItemById } from '@/../../shared/api/SharedClient'

// Validation and messages
import { validationMixin } from 'vuelidate'

// Constants
import { orderSatusSuccessAction, legalCompanyMissingCreditNumberError, legalCompanyMissingVatNumberError, missingCourseError, patchOrderErrorMessage, defaultErrorMessage, statusChangeNotSupportedError, requiredPurchaseOrderError } from '../../../../shared/constants/validationMessageHelper'
import { PROVIDER } from '@/constants/EdumsProviderConstants.json'
import { API_CALL_URL_PATHS, BUTTON_TEXT, WAIT_TIME } from '@/../../shared/constants/constantsGeneral.json'

// UserService
import UserService from '../../../../shared/services/UserService'

// Components
import ModalTabNavigation from '../../../../shared/components/TabNavigation/ModalTabNavigation.vue'
import IncompanyPurchaseAgreementLegalCompany from './IncompanyPurchaseAgreementLegalCompany.vue'
import IncompanyPurchaseAgreementCourseList from './IncompanyPurchaseAgreementCourseList.vue'
import IncompanyPurchaseAgreementFinance from './IncompanyPurchaseAgreementFinance.vue'

// Mixins
import { modalTabNavigationMixin } from '../../mixins/modalTabNavigationMixin'
import filterMixin from '@/../../shared/mixins/filterMixin'

export default {
	name: 'AcceptIncompanyPurchaseAgreementPopupComponent',
	components: {
		IncompanyPurchaseAgreementLegalCompany,
		IncompanyPurchaseAgreementCourseList,
		IncompanyPurchaseAgreementFinance,
		ModalTabNavigation
	},
	mixins: [validationMixin, modalTabNavigationMixin, filterMixin],
	props: {
		isModalVisible: {
			type: Boolean,
			default: false
		},
		orderNumber: {
			type: String,
			default: '-'
		},
		providerId: {
			type: String,
			default: null
		},
		accountId: {
			type: String,
			default: null
		}
	},
	data() {
		return {
			PROVIDER,
			userService: new UserService(),
			isAdministrator: false,
			BUTTON_TEXT,
			currentInvoiceItems: [],
			perPersonOrderCosts: null,
			legalCompanyList: [],
			selectedLegalCompanyId: null,
			legalCompanyDetails: null,
			isLegalCompanyCreditNumberValid: false,
			selectedCourse: null,
			isLegalCompanyVatNumberValid: false,
			selectedFilters: {
                searchQuery: ''
            },
			countryOptions: [],
			selectedCountry: 'NL',
			countries: null,
			vatPercentageOptions: [],
			vatAmount: '0',
			selectedVatPercentage: null,
			navigationTabs: [
				{
					title: PROVIDER.Order.Shared.Popups.AcceptRejectOrder.AcceptOrder.IncompanyPurchaseAgreement.Tabs.LegalCompany,
					id: 'handelsnamen',
					hasErrors: false
				},
				{
					title: PROVIDER.Order.Shared.Popups.AcceptRejectOrder.AcceptOrder.IncompanyPurchaseAgreement.Tabs.Course,
					id: 'leeraanbod',
					isDisabled: true,
					hasErrors: false
				},
				{
					title: PROVIDER.Order.Shared.Popups.AcceptRejectOrder.AcceptOrder.IncompanyPurchaseAgreement.Tabs.FinancialInfo,
					id: 'prijs',
					isDisabled: true,
					hasErrors: false
				}
			],
			activeTab: 'handelsnamen'
		}
	},
	computed: {
		...mapState('orderModule', ['order']),
		...mapGetters('orderModule', ['getProviderReferenceId']),
		legalCompanyOptions() {
			if (this.legalCompanyList) {
				return this.legalCompanyList.map(item => {
					return {
						value: item.id,
						displayText: item.name
					}
				})
			}
			return null
		},
		legalCompanyFilter() {
			let filter = 'deleted eq false'

			if(this.providerId) {
				filter += ` and providerId eq ${this.providerId}`
			}

			return filter
		},
		currentTab() {
			return this.findTabById(this.activeTab)
		},
		legalCompanyTab() {
			return this.findTabById('handelsnamen')
		},
		courseTab() {
			return this.findTabById('leeraanbod')
		},
		financeTab() {
			return this.findTabById('prijs')
		}
	},
	watch: {
		isModalVisible: function watchPropsChange(newValue) {
			// reset data status when modal is closed by watching the value
			if (newValue === false) {
				if (this.legalCompanyList.length > 1) {
					this.legalCompanyDetails = null
					this.legalCompanyId = null
				}
			}

			if(newValue === true) {
				this.getLegalCompanyList()
			}
		},
		providerId: {
			handler() {
				if (this.providerId) {
					this.getLegalCompanyList()
				}
			}
		}
	},
    async created() {
		this.isAdministrator = await this.userService.isAdministrator()
		this.getCountryListAndSetCountryTaxRates()
	},
	methods: {
		close() {
			this.$refs?.legalCompany?.$v?.$reset()
			this.$refs?.finance?.$v?.$reset()
			this.activeTab = 'handelsnamen'
			this.selectedLegalCompanyId = null
			this.legalCompanyDetails = null
			this.selectedCourse = null
			this.selectedFilters.searchQuery = null
			this.localFilters.searchQuery = null
			this.selectedCountry = 'NL'
			this.selectedVatPercentage = null
			this.currentInvoiceItems = []
			this.clearTabsValidation()
			this.disableAllButFirstTab(this.navigationTabs)
			this.$emit('close')
		},
		showManualNotification(type, propertyErrorMessage) {
			const toastNotification = {
				type,
				message: propertyErrorMessage
			}

			this.$store.dispatch('toastNotificationModule/add', toastNotification, {
				root: true
			})
		},
		async getLegalCompanyList() {
			try {
				const response = await getItems(
					process.env.VUE_APP_PROVIDER_API_URL, API_CALL_URL_PATHS.legalCompanies,
					1,
					99999,
					'name asc',
					this.legalCompanyFilter,
					false
				)
				if (!response) return
				this.legalCompanyList = response.items
			} catch (error) {
				console.error('Something went wrong while retrieving legal company list', error)
			}
		},
		handleVatNumberValidation(isValid) {
			this.isLegalCompanyVatNumberValid = isValid
		},
		handleCreditNumberValidation(isValid) {
			this.isLegalCompanyCreditNumberValid = isValid
		},
		setLegalCompanyId(id) {
			this.selectedLegalCompanyId = id
		},
		setLegalCompanyDetails(details) {
			this.legalCompanyDetails = {...details}
		},
		setSelectedCourse(course) {
			if(!course) this.selectedCourse = null

			else {
				this.selectedCourse = {...course}
			}
		},
		async getCountryListAndSetCountryTaxRates() {
			try {
				const response = await getItems(
					process.env.VUE_APP_PROVIDER_API_URL,
					API_CALL_URL_PATHS.countries,
					1,
					99999,
					'sortOrder, name',
					null,
					false
				)
				if (!response) return

				this.countries = response.items

				this.countries.reduce((acc, country) => {
				if (country) {
					this.countryOptions.push({
						displayText: country.displayName ? country.displayName : country.name,
						value: country.codeAlpha2
					})
				}
				return acc
				}, [])

				this.setVatOptions()

			} catch (error) {
				console.error('Something went wrong while retrieving country list', error)
			}
		},
		async setVatOptions() {
			const countryId = this.getSelectedCountryId(this.selectedCountry)
			const expandedProperty = 'taxRates'

			if (!countryId) return

			try {
				const response = await getItemById(process.env.VUE_APP_PROVIDER_API_URL, API_CALL_URL_PATHS.countries, countryId, expandedProperty, false)
				if (!response) return

				const localTaxRates = response.taxRates.map(taxRateItem => {
					return taxRateItem.taxRate
				})

				this.setCountryAndVatPercentageOptions(localTaxRates)

			} catch (error) {
				console.error('Something went wrong while retrieving country details', error)
			}
		},
		getSelectedCountryId() {
			if (this.countries.length === 0 || !this.selectedCountry) return null
			return this.countries.find(country => country.codeAlpha2 === this.selectedCountry).id
		},
		setCountryAndVatPercentageOptions(localTaxRates) {
			if (this.selectedCountry) {
				this.vatPercentageOptions = []

				// based on the selected country, recreate country-specific vatPercentageOptions for the BTW select dropdown in invoiceItem component
				localTaxRates.reduce((acc, taxRate) => {
					if (taxRate !== null) {
						const newOption = {
							displayText: `${taxRate}%`,
							value: taxRate
						}

						this.vatPercentageOptions.push(newOption)
					}
					return acc
				}, [])
			}
		},
		setCountry(country) {
			this.selectedCountry = country
			this.selectedVatPercentage = null
			this.vatAmount = '0'
			this.setVatOptions()
		},
		setVatPercentage(vatPercentage) {
			this.selectedVatPercentage = vatPercentage
            this.setVatAmount()
		},
		setInvoiceItemCostType(invoiceItemIndex, newValue){
			const currentInvoiceItem = this.currentInvoiceItems[invoiceItemIndex]
			currentInvoiceItem.costType = newValue
		},
		setInvoiceItemVatPercentage(invoiceItemIndex, newValue){
			const currentInvoiceItem = this.currentInvoiceItems[invoiceItemIndex]
            currentInvoiceItem.vatPercentage = newValue
		},
		setInvoiceItemPriceBeforeTax(invoiceItemIndex, newValue){
			const currentInvoiceItem = this.currentInvoiceItems[invoiceItemIndex]
            currentInvoiceItem.priceBeforeTax = newValue
		},
		setInvoiceItemCountryAndVatPercentageOptions(invoiceItemIndex, invoiceItem){
			const currentInvoiceItem = this.currentInvoiceItems[invoiceItemIndex]
			currentInvoiceItem.country = invoiceItem.vatCountry
            currentInvoiceItem.vatPercentage = invoiceItem.vatPercentage
            currentInvoiceItem.vatPercentageOptions = invoiceItem.vatPercentageOptions
			currentInvoiceItem.displayVatPercentageOptions = invoiceItem.displayVatPercentageOptions
		},
		setPerPersonOrderCosts(perPersonOrderCosts){
			this.perPersonOrderCosts = perPersonOrderCosts
		},
		removeInvoiceItem(invoiceItemIndex){
			this.currentInvoiceItems.splice(invoiceItemIndex, 1)
		},
		addInvoiceItem(){
			// add empty invoice item
			const emptyInvoiceItem = {
                quantity: 1,
                priceBeforeTax: null,
                vatAmount: 0,
                vatCountry: 'NL',
                vatPercentage: null,
                vatPercentageOptions: [],
                vatExemptAmount: 0,
                vatExemptAmountVAT: 0,
                costType: null,
                isOptional: false,
                displayVatPercentageOptions: false,
                priceIncludingTax: 0
            }
            const newEmptyInvoiceItem = {...emptyInvoiceItem}
			this.currentInvoiceItems.push(newEmptyInvoiceItem)
		},
		performCalculations(invoiceItemIndex){
			const currentInvoiceItem = this.currentInvoiceItems[invoiceItemIndex]
			if(!currentInvoiceItem){
				return
			}
			currentInvoiceItem.vatAmount = ((currentInvoiceItem.vatPercentage / 100) * currentInvoiceItem.priceBeforeTax).toFixed(2).replace(/(\.0+|0+)$/, '')
            currentInvoiceItem.priceIncludingTax = currentInvoiceItem.priceBeforeTax + Number(currentInvoiceItem.vatAmount)
		},
		setVatAmount() {
			this.vatAmount = ((this.selectedVatPercentage / 100) * this.order?.orderLines[0].subtotalWithoutVAT).toFixed(2).replace(/(\.0+|0+)$/, '')
		},
		findTabById(id) {
			return this.navigationTabs.find(tab => tab.id === id)
		},
		async patchOrder(orderId, objectToBePatched) {
			if (!orderId) return;
			try {
				const response = await patchItem(process.env.VUE_APP_ORDER_API_URL, API_CALL_URL_PATHS.orders, orderId, objectToBePatched, false);
				if (!response) return;
			} catch (error) {
				console.error(`Something went wrong while patching order with ID ${orderId}`, error);
				throw error;
			}
		},
		mapInvoiceItemsIntoOrderLines(){
			let orderLines = []
			let sequenceNumber = 2; // MD: there will already be 1 order line for the purchase agreement price without vat
			for (const invoiceItem of this.currentInvoiceItems) {
				const orderLine = {
					sequenceNumber: sequenceNumber++,
					costType: invoiceItem.costType,
					subTotalWithoutVat: invoiceItem.priceBeforeTax,
					vatPercentage: invoiceItem.vatPercentage,
					vatCountry: invoiceItem.vatCountry
				}
				orderLines.push(orderLine)
			}
			return orderLines
		},
		async patchOrderLinesForEachSequenceNumber(orderLineSequenceNumber) {
			const patchObjList = []

			const invoiceOnBehalfOfNameObj = {
				value: this.legalCompanyDetails.name,
				path: 'invoiceOnBehalfOfName',
				op: 'replace'
			}
			patchObjList.push(invoiceOnBehalfOfNameObj)

			const invoiceOnBehalfOfVatNumberObj = {
				value: this.legalCompanyDetails.vatNumber,
				path: 'invoiceOnBehalfOfVatNumber',
				op: 'replace'
			}
			patchObjList.push(invoiceOnBehalfOfVatNumberObj)

			if(orderLineSequenceNumber === 1){
				const vatPercentageObj = {
					value: this.selectedVatPercentage,
					path: 'vatPercentage',
					op: 'replace'
				}
	
				const vatCountryObj = {
					value: this.selectedCountry,
					path: 'vatCountry',
					op: 'replace'
				}
	
				patchObjList.push(vatPercentageObj, vatCountryObj)
			}

			await patchItem(process.env.VUE_APP_ORDER_API_URL, API_CALL_URL_PATHS.orders, `${this.order.id}/lines/${orderLineSequenceNumber}`, patchObjList, false).then(response => {
				if (!response) return
			})
		},
		async updateOrderData(){
			try {
				// patch order with course id
				let setObjectToBePatched = [
					{
						value: this.selectedCourse.id,
						path: 'productReference',
						op: 'replace'
					}
				]
				await this.patchOrder(this.order.id, setObjectToBePatched)

				// post order lines
				const orderLines = this.mapInvoiceItemsIntoOrderLines()
				const finalOrderLines = [this.order.orderLines[0], ...orderLines];
				await postItem(
					process.env.VUE_APP_ORDER_API_URL, 
					`${API_CALL_URL_PATHS.orders}/${this.order.id}/lines`, 
					orderLines,
					false
				)
				.catch((error) => { 
					this.showManualNotification('error', defaultErrorMessage)
					console.error('Something went wrong while posting order lines', error)
				})

				// patch order to include 'invoice on behalf of' information that allows order status change afterwards
				for (const orderLine of finalOrderLines) {
					await this.patchOrderLinesForEachSequenceNumber(orderLine.sequenceNumber)
				}
			} catch (error) {
				this.getNotificationError(error);
				throw error;
			}
			
		},
		async transitionOrder(){
			// approve order status
			if(this.order.status !== PROVIDER.Order.Shared.OrderStatus.AcceptedProvider) {
				const postObj = { status: PROVIDER.Order.Shared.OrderStatus.AcceptedProvider}
				await this.postOrderStatusTransition(postObj)
			}
		},
		async submitHandler() {
			if (!this.currentTab) {
				return;
			}
			// Check if Legal Company step is valid
			if(this.currentTab.id === 'handelsnamen') {
				if(!this.validateLegalCompany()) return
				this.legalCompanyTab.hasErrors = false
			}

			// Check if Course step is valid
			if(this.currentTab.id === 'leeraanbod') {
				if(!this.validateCourseSelection()) return
				this.courseTab.hasErrors = false
			}

			if(this.currentTab.id === 'prijs') {
				if(!this.validateFinance()) return
				this.financeTab.hasErrors = false

				try {
					await this.updateOrderData();
					await this.transitionOrder()
				
					this.getOrdersAfterRebuildOrderIndex(WAIT_TIME.indexRebuild)
					this.close()
					
				} catch (error) {
					console.error('Something went wrong in updateOrderData', error);
				}
			}

			this.enableNextTab()
			this.nextTab()
		},
		getOrdersAfterRebuildOrderIndex(millisecond) {
			this.timeout = setTimeout(() => this.$bus.emit('refresh-orders'), millisecond)
		},
		postOrderStatusTransition(postObject) {
            postItem(process.env.VUE_APP_ORDER_API_URL, `${API_CALL_URL_PATHS.orders}/${this.order.id}/status-transitions`, postObject, false)
                .then(() => {
                    const toastNotification = {
                        type: 'success',
                        message: orderSatusSuccessAction('bijgewerkt')
                    }

                    this.$store.dispatch('toastNotificationModule/add', toastNotification, {
                        root: true
                    })
                })
                .catch((error) => {
                    this.getNotificationError(error)
                    console.error('Something went wrong while posting order status', error)
                })
        },
		getNotificationError(error) {
            const toastNotification = {
                type: 'error',
                message: null
            }

            const errorData = error && error.response && error.response.data
            if (errorData && errorData.error && errorData.error.message === 'A purchase order reference was not provided and this account requires one to complete this order status transition.') {
                toastNotification.message = requiredPurchaseOrderError
            } else if (errorData && errorData.error && errorData.error.message === 'The requested status change is not allowed.') {
                toastNotification.message = statusChangeNotSupportedError
            } else if (errorData && errorData.error && errorData.error.message === 'Course invoice items total without VAT do not match purchase agreement list price without VAT.') {
				toastNotification.message = patchOrderErrorMessage
			} else {
                toastNotification.message = defaultErrorMessage
            }

            this.$store.dispatch('toastNotificationModule/add', toastNotification, {
                root: true
            })
        },
		validateLegalCompany() {
			this.$refs.legalCompany.$v.$touch()
			if(this.$refs.legalCompany.$v.$invalid) {
				this.legalCompanyTab.hasErrors = true
				return false
			}

			if (!this.isLegalCompanyCreditNumberValid) {
				this.showManualNotification('error', legalCompanyMissingCreditNumberError())
				this.legalCompanyTab.hasErrors = true
				return false
			}

			if (!this.isLegalCompanyVatNumberValid) {
				this.showManualNotification('error', legalCompanyMissingVatNumberError)
				this.legalCompanyTab.hasErrors = true
				return false
			}

			return true
		},
		validateCourseSelection() {
			if(!this.selectedCourse) {
				this.showManualNotification('error', missingCourseError)
				this.courseTab.hasErrors = true
				return false
			}

			return true
		},
		validateFinance() {
			this.$refs.finance.$v.$touch()
			if(this.$refs.finance.$v.$invalid) {
				this.financeTab.hasErrors = true
				return false
			}

			// sum of per person invoice items must equal purchaseAgreement perPersonWithoutVat
			if(this.perPersonOrderCosts.purchaseAgreementPricePerPersonWithoutVat !== this.perPersonOrderCosts.currentPricePerPersonWithoutVat){
				this.financeTab.hasErrors = true
				this.showManualNotification('error', PROVIDER.Order.Shared.Popups.AcceptRejectOrder.AcceptOrder.IncompanyPurchaseAgreement.FinanceTab.ErrorMessages.PerPersonCostsDontMatch)
				return false
			}

			return true
		},
		updateStep(tabId) {
			if(this.currentTab && this.currentTab.id === 'handelsnamen' && !this.validateLegalCompany()) {
				this.legalCompanyTab.hasErrors = true
				return
			}

			if(this.currentTab && this.currentTab.id === 'leeraanbod' && !this.validateCourseSelection()) {
				this.courseTab.hasErrors = true
				return
			}

			if(this.currentTab && this.currentTab.id === 'prijs' && !this.validateFinance()) {
				this.financeTab.hasErrors = true
				return
			}

			this.clearTabsValidation()
			this.changeStep(tabId)
        },
		goBack() {
			if(this.currentTab && this.currentTab.id === 'leeraanbod' && !this.validateCourseSelection()) {
				this.courseTab.hasErrors = true
				return
			}

			if(this.currentTab && this.currentTab.id === 'prijs' && !this.validateFinance()) {
				this.financeTab.hasErrors = true
				return
			}

			this.clearTabsValidation()
			this.previousTab()
		},
		enableNextTab() {
			const currentIndex = this.navigationTabs.findIndex(tab => tab.id === this.currentTab.id)
			const nextIndex = currentIndex + 1
			if (nextIndex < this.navigationTabs.length) {
				this.navigationTabs[nextIndex].isDisabled = false
			}
		}
	}
}
</script>
