<template>
    <div class="d-flex justify-content-between align-items-center flex-wrap mb-2">
        <div :class="{'mb-4': selectedLegalCompanyIdErrorMessage}">
            {{ `${PROVIDER.Order.Shared.Popups.AcceptRejectOrder.AcceptOrder.LegalCompany.Label}*` }}
        </div>
        <recess-multi-select
            :selected-options="selectedLegalCompanyId"
            :options="legalCompanyOptions"
            :search-input="true"
            :single-select="true"
            class="w-70 qa-legal-company-select"
            :error-message="selectedLegalCompanyIdErrorMessage"
            :placeholder="PROVIDER.Order.Shared.Popups.AcceptRejectOrder.AcceptOrder.LegalCompany.Placeholder"
            @input="(newValue) => legalCompanySelectHandler(newValue)"
            @blur="$v.selectedLegalCompanyId.$touch()"
        />
        <p v-if="!isAdmin" 
            class="mt-3 font-italic"
        >
            {{ PROVIDER.Order.Shared.Popups.AcceptRejectOrder.AcceptOrder.LegalCompanyButton.PartOne }}
            <recess-button
                :title="PROVIDER.Order.Shared.Popups.AcceptRejectOrder.AcceptOrder.LegalCompanyButton.Title"
                variant="variant4"
                class="mb-4"
                url="/leveranciersgegevens/handelsnamen"
            />
            {{ PROVIDER.Order.Shared.Popups.AcceptRejectOrder.AcceptOrder.LegalCompanyButton.PartTwo }}
        </p>
  
        <template v-if="details">
            <legal-company-detail :legal-company-details="details" />
        </template>
    </div>
</template>

<script>
// Constants and validations
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { PROVIDER } from '@/constants/EdumsProviderConstants.json'
import { API_CALL_URL_PATHS, BUTTON_TEXT } from '@/../../shared/constants/constantsGeneral.json'
import { isRequiredErrorMessage } from '@/../../shared/constants/validationMessageHelper.js'
// Apis
import { getItemById } from '@/../../shared/api/SharedClient'
// Components
const LegalCompanyDetail = () => import('@/components/molecules/shared/LegalCompanyDetail')

export default {
  name: 'IncompanyPurchaseAgreementLegalCompany',
  components: {
    LegalCompanyDetail
  },
  mixins: [validationMixin],
  props: {
    providerId: {
      type: String,
      default: null
    },
    isAdmin: {
      type: Boolean,
      default: false
    },
    legalCompanyOptions: {
      type: Array,
      default: () => []
    },
    legalCompanyList: {
      type: Array,
      default: () => []
    },
    legalCompanyId: {
      type: String,
      default: null
    },
    details: { 
      type: Object,
      default: null
    }
  },
  data() {
    return {
      PROVIDER,
      BUTTON_TEXT,
      selectedLegalCompanyId: this.legalCompanyId
    }
  },
  validations: {
    selectedLegalCompanyId: { required }
  },
  computed: {
    selectedLegalCompanyIdErrorMessage() {
      return isRequiredErrorMessage('selectedLegalCompanyId', 'Handelsnaam', this.$v.selectedLegalCompanyId)
    }
  },
  watch: {
    selectedLegalCompanyId() {
      this.checkLegalCompanyCreditNumberValidity()
      this.checkLegalCompanyVatNumberValidity()
    }
  },
  methods: {
    legalCompanySelectHandler(newValue) {
      this.selectedLegalCompanyId = newValue
      this.$emit('setLegalCompanyId', this.selectedLegalCompanyId)
      this.getLegalCompanyDetails()
    },
    async getLegalCompanyDetails() {
      if (!this.selectedLegalCompanyId) return
      try {
        const response = await getItemById(
          process.env.VUE_APP_PROVIDER_API_URL,
          API_CALL_URL_PATHS.legalCompanies,
          this.selectedLegalCompanyId,
          null,
          false
        )
        if (!response) return

        this.$emit('setLegalCompanyDetails', response)

      } catch (error) {
        console.error('Something went wrong while retrieving legal companies', error)
      }
    },
    checkLegalCompanyCreditNumberValidity() {
      const legalCompany = this.legalCompanyList !== null && this.legalCompanyList.find(lCompany => lCompany.id === this.selectedLegalCompanyId)

      if (!legalCompany) return

      const isValid = legalCompany.creditNumber !== null && legalCompany.creditNumber !== undefined

      this.$emit('isLegalCompanyCreditNumberValid', isValid)
    },
    checkLegalCompanyVatNumberValidity() {
      const legalCompany = this.legalCompanyList !== null && this.legalCompanyList.find(lCompany => lCompany.id === this.selectedLegalCompanyId)

      if (!legalCompany) return

      const isValid = legalCompany.vatNumber !== null && legalCompany.vatNumber !== undefined && legalCompany.vatNumber !== ''

      this.$emit('isLegalCompanyVatNumberValid', isValid)
    }
  }
}
</script>