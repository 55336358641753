<template>
    <div>
        <p>{{ PROVIDER.Order.Shared.Popups.AcceptRejectOrder.AcceptOrder.IncompanyPurchaseAgreement.FinanceTab.Title }}</p>
        <!-- Country and VAT Selection -->
        <h4 class="mb-4">{{ PROVIDER.Order.Shared.Popups.AcceptRejectOrder.AcceptOrder.IncompanyPurchaseAgreement.FinanceTab.FixedCosts.Title }}</h4>
        <div class="row">
            <recess-multi-select
                class="col-3 qa-incompany-purchase-agreement-vat-country"
                :selected-options="selectedCountry"
                :options="countryOptions"
                :search-input="true"
                :single-select="true"
                :label-text="`${PROVIDER.Order.Shared.Popups.AcceptRejectOrder.AcceptOrder.IncompanyPurchaseAgreement.FinanceTab.FixedCosts.Country.Label}*`"
                :error-message="vatCountryError"
                @input="(newValue) => onCountryChange(newValue)"
                @blur="$v.country.$touch()"
            />

            <!-- show vatPercentage as dropdown if vatPercentageOptions is not null-->
            <recess-multi-select
                v-if="vatPercentageOptions.length"
                class="col-3 qa-incompany-purchase-agreement-vat-percentage"
                :selected-options="selectedVatPercentage"
                :options="vatPercentageOptions"
                :single-select="true"
                :label-text="`${PROVIDER.Order.Shared.Popups.AcceptRejectOrder.AcceptOrder.IncompanyPurchaseAgreement.FinanceTab.FixedCosts.Percentage.Label}*`"
                :error-message="vatPercentageError"
                @input="(newValue) => onVatPercentageChange(newValue)"
                @blur="$v.vat.$touch()"
            />

            <!-- show vatPercentage as input field if vatPercentageOptions is null -->
            <recess-input
                v-if="!vatPercentageOptions.length"
                class="col-3 qa-incompany-purchase-agreement-vat-percentage-input" 
                :value="selectedVatPercentage"
                :label-text="`${PROVIDER.Order.OrderOverview.Popup.ApproveOrder.VatPercentage.Label}*`"
                :placeholder-text="PROVIDER.Portfolio.CourseCreate.Form.InvoiceItem.VatPercentage.Placeholder"
                :error-message="vatPercentageError"
                @input="(newValue) => onVatPercentageChange(newValue)"
                @blur="$v.vat.$touch()"
            />

            <div class="col-6 d-flex justify-content-end ">
                <div class="d-flex mr-4 flex-column qa-price-subtotal-without-vat-value">
                    <div class="c-recess-input__label">{{ PROVIDER.Order.Shared.Popups.AcceptRejectOrder.AcceptOrder.IncompanyPurchaseAgreement.FinanceTab.FixedCosts.PriceBeforeTax.Label }}</div>
                    <!-- subTotalWithoutVat corresponds to the listPriceWithoutVat from the purchaseAgreement which will always be the first order line -->
                    <p class="m-0 u-height-42 d-flex align-items-center justify-content-end">{{ subTotalWithoutVat | currency }}</p>
                </div>
                <div class="d-flex mr-4 flex-column qa-price-vat-value">
                    <div class="c-recess-input__label text-right">{{ PROVIDER.Order.Shared.Popups.AcceptRejectOrder.AcceptOrder.IncompanyPurchaseAgreement.FinanceTab.FixedCosts.VatAmount.Label }}</div>
                    <p class="m-0 u-height-42 d-flex align-items-center justify-content-end">{{ Number(vatAmount) | currency }}</p>
                </div>
                <div class="d-flex flex-column qa-price-with-vat-value">
                    <div class="c-recess-input__label p-0">{{ PROVIDER.Order.Shared.Popups.AcceptRejectOrder.AcceptOrder.IncompanyPurchaseAgreement.FinanceTab.FixedCosts.PriceWithTax.Label }}</div>
                    <p class="m-0 u-height-42 d-flex align-items-center justify-content-end">{{ Number(priceIncludingTax) | currency }}</p>
                </div>
            </div>
        </div>
        <h4 class="mt-5">{{ PROVIDER.Order.Shared.Popups.AcceptRejectOrder.AcceptOrder.IncompanyPurchaseAgreement.FinanceTab.PerPersonCosts.PricePerPerson.Label }}</h4>
        <div class="row">
            <p class="col-7" style="font-size : 14px">{{ PROVIDER.Order.Shared.Popups.AcceptRejectOrder.AcceptOrder.IncompanyPurchaseAgreement.FinanceTab.PerPersonCosts.PurchaseAgreementTotalPerPersonWithoutVat.Label }}</p>

            <div class="col-2 d-flex justify-content-end ">
                
                <div class="d-flex mr-4 flex-column qa-price-per-person-available-value">
                    <p>{{ purchaseAgreementPricePerPersonWithoutVat | currency }}</p>
                </div>
            </div>
        </div>
        <!-- Per person costs -->
        <div v-for="(invoiceItem, invoiceItemIndex) in invoiceItems" class="qa-invoice-item">
            <div class="row">
                <!-- CostType -->
                <recess-multi-select
                    :selected-options="invoiceItem.costType"
                    :options="costTypes"
                    :search-input="true"
                    :single-select="true"
                    :label-text="PROVIDER.Portfolio.CourseCreate.Form.InvoiceItem.CostType.Label"
                    :placeholder="PROVIDER.Portfolio.CourseCreate.Form.InvoiceItem.CostType.Placeholder"
                    :error-message="invoiceItemCostTypeError(invoiceItemIndex)"
                    :class="`col 4 qa-order-line-cost-type-${invoiceItemIndex}`" 
                    @input="(newValue) => onInvoiceItemCostTypeChange(invoiceItemIndex, newValue)"
                />
                    
                <div class="col-8 d-flex justify-content-end"> 
                    <div class="d-flex align-items-center" v-if="invoiceItems && invoiceItems.length > 0">
                        <h6 class="m-0">{{ PROVIDER.Portfolio.CourseCreate.Form.InvoiceItem.Delete }}</h6>
                        <span
                            :class="`ml-4 c-icon c-icon__bin qa-remove-invoice-item-button-${invoiceItemIndex}`"
                            :data-test="`remove-invoice-item-button-${invoiceItemIndex}`"
                            @click="removeInvoiceItem(invoiceItemIndex)"
                        >
                        </span>
                    </div> 
                </div>
            </div>
                
            <div class="row">
                <!-- PriceBeforeTax -->
                <recess-numeric-input
                    v-model="invoiceItem.priceBeforeTax"
                    :label-text="PROVIDER.Portfolio.CourseCreate.Form.InvoiceItem.PriceBeforeTax.Label"
                    :placeholder-text="PROVIDER.Portfolio.CourseCreate.Form.InvoiceItem.PriceBeforeTax.Placeholder"
                    :error-message="invoiceItemPriceBeforeTaxError(invoiceItemIndex)"
                    :class="`col-3 qa-order-line-price-before-tax-${invoiceItemIndex}`"
                    @input="(newValue) => onPriceBeforeTaxChange(invoiceItemIndex, newValue)"
                />
                <!-- VatCountry: default value is NL -->
                <recess-multi-select
                    class="col-3 qa-incompany-purchase-agreement-vat-country"
                    :selected-options="invoiceItem.vatCountry"
                    :options="countryOptions"
                    :search-input="true"
                    :single-select="true"
                    :label-text="`${PROVIDER.Order.Shared.Popups.AcceptRejectOrder.AcceptOrder.IncompanyPurchaseAgreement.FinanceTab.FixedCosts.Country.Label}*`"
                    :error-message="vatCountryError"
                    @input="(newValue) => onInvoiceItemCountryChange(invoiceItemIndex, newValue)"
                />
                <!-- VatPercentage show vatPercentage as dropdown if vatPercentageOptions is not null -->
                <recess-multi-select
                    v-if="invoiceItem.vatPercentageOptions.length"
                    class="col-2 qa-incompany-purchase-agreement-vat-percentage"
                    :selected-options="invoiceItem.vatPercentage"
                    :options="invoiceItem.vatPercentageOptions"
                    :single-select="true"
                    :label-text="`${PROVIDER.Order.Shared.Popups.AcceptRejectOrder.AcceptOrder.IncompanyPurchaseAgreement.FinanceTab.FixedCosts.Percentage.Label}*`"
                    :error-message="invoiceItemVatPercentageError(invoiceItemIndex)"
                    @input="(newValue) => onInvoiceItemVatPercentageChange(invoiceItemIndex, newValue)"
                />
                <!-- show vatPercentage as input field if vatPercentageOptions is null -->
                <recess-input
                    v-if="!invoiceItem.displayVatPercentageOptions"
                    :value="invoiceItem.vatPercentage"
                    :label-text="`${PROVIDER.Portfolio.CourseCreate.Form.InvoiceItem.VatPercentage.Label}${'(%) *'}`"
                    :placeholder-text="PROVIDER.Portfolio.CourseCreate.Form.InvoiceItem.VatPercentage.Placeholder"
                    :error-message="invoiceItemVatPercentageError(invoiceItemIndex)"
                    :class="`col-2 qa-course-vat-percentage-input-${invoiceItemIndex}`" 
                    @input="(newValue) => onInvoiceItemVatPercentageChange(invoiceItemIndex, newValue)"
                />

                <div class="col-4 d-flex justify-content-end ">
                    <div class="d-flex mr-4 flex-column qa-order-line-vat-amount-value">
                        <div class="c-recess-input__label text-right">{{ PROVIDER.Order.Shared.Popups.AcceptRejectOrder.AcceptOrder.IncompanyPurchaseAgreement.FinanceTab.FixedCosts.VatAmount.Label }}</div>
                        <p class="m-0 u-height-42 d-flex align-items-center justify-content-end">{{ invoiceItem.vatAmount | currency }}</p>
                    </div>
                    <div class="d-flex mr-4 flex-column qa-order-line-vat-amount-value">
                        <div class="c-recess-input__label">{{ PROVIDER.Order.Shared.Popups.AcceptRejectOrder.AcceptOrder.IncompanyPurchaseAgreement.FinanceTab.FixedCosts.PriceWithTax.Label }}</div>
                        <p class="m-0 u-height-42 d-flex align-items-center justify-content-end">{{ Number(invoiceItem.priceIncludingTax) | currency }}</p>
                    </div>
                </div>
            </div>
            <div v-if="invoiceItem">
                <recess-divider variant="small"  show-line class="pt-0" />
            </div>
        </div>

        	<!-- add invoice button -->
		<div class="d-flex justify-content-end">
			<button-component
				type="button"
				:back-label="PROVIDER.Portfolio.CourseCreate.Form.InvoiceItem.AddInvoiceItemButton"
				icon-font="add"
				:button-class="`m-0 c-button--variant4 qa-add-invoice-item-button`"
				data-test="add-invoice-item-button"
				@click.native.prevent="addInvoiceItem()"
			/>
		</div>

        <div class="mt-5">
			<div>
				<div class="d-flex justify-content-between align-items-center qa-invoice-total-before-tax">
					<h4 class="mb-0">{{ PROVIDER.Order.Shared.Popups.AcceptRejectOrder.AcceptOrder.IncompanyPurchaseAgreement.FinanceTab.PerPersonCosts.TotalPerPersonWithoutVat.Label }}</h4>
					<h4 class="d-flex align-items-center mb-0">{{ totalPriceBeforeTax | currency }}</h4>
				</div>
				<recess-divider variant="small" show-line />
			</div>

			<div>
				<div class="d-flex justify-content-between align-items-center qa-invoice-total-including-tax">
					<h4 class="mb-0">{{ PROVIDER.Order.Shared.Popups.AcceptRejectOrder.AcceptOrder.IncompanyPurchaseAgreement.FinanceTab.PerPersonCosts.TotalPerPersonWithVat.Label }}</h4>
					<h4 class="d-flex align-items-center mb-0">{{ totalPriceIncludingTax | currency }}</h4>
				</div>
				<recess-divider variant="small" show-line />
			</div>
		</div>
    </div>
</template>
<script>

// Validation and messages
import { isRequiredErrorMessage, invalidPercentageError, vatPercentageMinMaxValue } from '@/../../shared/constants/validationMessageHelper.js'

// vuelidate
import { validationMixin } from 'vuelidate'
import { required, between, minValue } from 'vuelidate/lib/validators' 

// Constants
import { PROVIDER } from '@/constants/EdumsProviderConstants.json'
import { API_CALL_URL_PATHS } from '@/../../shared/constants/constantsGeneral.json'

// shared client
import { getEnumList, getItems, getItemById } from '@/../../shared/api/SharedClient'

// components
import ButtonComponent from '@/components/atoms/ButtonComponent'

// vuex
import { mapState } from 'vuex'

export default {
	name: 'IncompanyPurchaseAgreementFinance',
    components: {
		ButtonComponent
	},
	mixins: [validationMixin],
	props: {
        selectedCountry: {
            type: String,
            default: 'NL'
        },
        vatPercentageOptions: {
            type: Array,
            default: () => []
        },
        invoiceItems: {
            type: Array,
            default: () => []
        },
        selectedVatPercentage: {
            type: Number,
            default: null
        },
        subTotalWithoutVat: {
            type: Number,
            default: null
        },
        vatAmount: {
            type: String,
            default: null
        },
	},
	validations: {
    	country: { required },
		vat: { 
			required,
			between: between(0, 100),
			is2DecimalPoints() {
				if(!this.vat) return true

				const decimalPoints = new RegExp(/^\-?[0-9]+(?:\.[0-9]{1,2})?$/)
				
				return decimalPoints.test(this.vat)
			}
		},
		invoiceItems: {
			$each: {
				costType: { required },
				priceBeforeTax: { required, minValue: minValue(0) },
				vatCountry: { required },
				vatPercentage: { between: between(0, 100), required },
				vatPercentageOptions: {},
				vatAmount: { required },
				vatExemptAmount: { required, minValue: minValue(0) },
				vatExemptAmountVAT: { required, minValue: minValue(0) }
			}
		}
	},
	data() {
		return {
			PROVIDER,
            country: this.selectedCountry,
            vat: this.selectedVatPercentage,
            costTypes: [],
            countries: [],
            countryOptions: [],
            currentInvoiceItems: [],
            currentPricePerPersonWithoutVat: 0,
            purchaseAgreementPricePerPersonWithoutVat: 0
		}
	},	
    computed: {
        ...mapState('orderModule', ['order']),
        setInvoices() {
			return this.invoiceItems
		},
        vatCountryError() {
			return isRequiredErrorMessage('vatCountry', 'Land', this.$v.country)
		},
		vatPercentageError() {
			if (!this.$v.vat.between || !this.$v.vat.is2DecimalPoints) {
				return invalidPercentageError
			}
			
			return isRequiredErrorMessage('vatPercentage', 'BTW', this.$v.vat)
		},
        priceIncludingTax() {
			return this.subTotalWithoutVat + Number(this.vatAmount)
		},
        totalPriceBeforeTax() {
			return this.invoiceItems.reduce((sum, item) => sum + item.priceBeforeTax, 0);

		},
        totalPriceIncludingTax() {
			return this.invoiceItems.reduce((sum, item) => sum + item.priceIncludingTax, 0);
		}
    },
    watch: {
        country() {
            this.vat = null
        },
        setInvoices: {
			handler() {
				this.invoiceItems = this.setInvoices
				this.counterToPreventInfiniteLoop += 1 

				if(this.invoiceItems && this.invoiceItems.length > 0 && this.invoiceItems !== undefined) {		

					if(this.counterToPreventInfiniteLoop === 1) { 
						this.getCountryListAndSetCountryTaxRates()
					}
				}
			},
			deep: true
		}
    },
    mounted(){
        this.getCountryListAndSetCountryTaxRates()
        this.setPurchaseAgreementData()
        this.getCostTypes()
    },
	methods: {
        removeEmptyInvoiceItem() {
            if (!this.invoiceItems) return   

			this.invoiceItems.forEach((invoiceItem, invoiceIndex) => {
				if(invoiceItem.costType === null) {
					this.invoiceItems.splice(invoiceIndex, 1) 
				}
			})
        },
        invoiceItemCostTypeError(invoiceItemIndex) {
			return isRequiredErrorMessage('costType', 'Investering', this.$v.invoiceItems.$each[invoiceItemIndex].costType )
		},
        async setPurchaseAgreementData(){
            const purchaseAgreement = await getItemById(
                process.env.VUE_APP_ORDER_API_URL,
                API_CALL_URL_PATHS.purchaseAgreements,
                this.order.purchaseAgreementId,
                null,
                false
            )

            this.purchaseAgreementPricePerPersonWithoutVat = purchaseAgreement.pricePerPersonWithoutVat
            this.updatePerPersonOrderCosts()
        },
        async getCountryListAndSetCountryTaxRates() {
			try {
				const response = await getItems(
					process.env.VUE_APP_PROVIDER_API_URL, 
					API_CALL_URL_PATHS.countries, 
					1, 
					99999, 
					'sortOrder, name',
					null, 
					false
				)
				if (!response) return 
				this.countries = response.items 
				this.setCountryOptions()   
				
			} catch (error) {
				console.error('Something went wrong while retrieving country list', error)
			}
		},
        setCountryOptions() {  
			this.countries.reduce((acc, country) => {
				if (country) {
					this.countryOptions.push({
						displayText: country.displayName ? country.displayName : country.name,
						value: country.codeAlpha2
					})
				}
				return acc
			}, [])

			this.setVatPercentageOptions() 
		},
        async setVatPercentageOptions() {
			if (this.invoiceItems && this.invoiceItems.length > 0) { 
				this.invoiceItems.forEach((invoiceItem) => {
					this.setCountryAndCountryTaxRates(invoiceItem, false)
				})
			}
		},
        removeInvoiceItem(invoiceItemIndex) {
			// reset invoiceItem
            const currentInvoiceItem = this.invoiceItems[invoiceItemIndex]
			currentInvoiceItem.priceBeforeTax = 0
            
            this.performCalculations(currentInvoiceItem)
            this.$emit('removeInvoiceItem', invoiceItemIndex)
		},
        async addInvoiceItem() {
            // throw error validations if there are still required fields to fill
			const invoiceWithRequiredFieldsEmpty = this.invoiceItems.filter((invoiceItem) => invoiceItem.costType === null || invoiceItem.priceBeforeTax === null || invoiceItem.vatPercentage === null)
			if(invoiceWithRequiredFieldsEmpty.length >= 1) {
                this.$v?.$touch()
                return
			}

            this.$emit('addInvoiceItem')
			await this.getCountryListAndSetCountryTaxRates()
		},
        getSelectedCountryId(selectedCountry) {			
			if (this.countries.length === 0 || !selectedCountry) return null
			return this.countries.find((country) => country.codeAlpha2 === selectedCountry).id
		},
        async setCountryAndCountryTaxRates(invoiceItem, resetVatPercentage) {   
			const countryId = this.getSelectedCountryId(invoiceItem.vatCountry)
			const expandedProperty = 'taxRates'

			if (!countryId) return   
 
			try {
				const response = await getItemById(process.env.VUE_APP_PROVIDER_API_URL, API_CALL_URL_PATHS.countries, countryId, expandedProperty, false)
				if (!response) return 
 
				const localTaxRates = response.taxRates.map((taxRateItem) => {
					return taxRateItem.taxRate
				})   
 
				this.setCountryAndVatPercentageOptions(invoiceItem, localTaxRates, resetVatPercentage)
  
			} catch (error) {
				console.error('Something went wrong while retrieving country details', error)
			}			 
		 },
         setCountryAndVatPercentageOptions(invoiceItem, localTaxRates, resetVatPercentage) {
			if (invoiceItem && invoiceItem.vatCountry) {
				// reset vatPercentageOptions array & vat percentage when country value changes
				if (resetVatPercentage) {
					invoiceItem.vatPercentage = null
				}
				invoiceItem.vatPercentageOptions = []  

				// based on the selected country, recreate country-specific vatPercentageOptions for the BTW select dropdown in invoiceItem component
				localTaxRates.reduce((acc, taxRate) => {
					if (taxRate !== null) {
						const newOption = {
							displayText: `${taxRate}%`,
							value: taxRate
						}

						invoiceItem.vatPercentageOptions.push(newOption)  
					}
					return acc
				}, [])
			} 

			if(localTaxRates.length > 0) {
				invoiceItem.displayVatPercentageOptions = true	
				return
			}

			invoiceItem.displayVatPercentageOptions = false
		},
        async getCostTypes() {
			try {
				const response = await getEnumList(
					process.env.VUE_APP_PROVIDER_API_URL, 
					API_CALL_URL_PATHS.enumerations, 
					'costType',
					null,
					null, 
					false
				)
				if (!response) return
				this.costTypes = response 
			} catch (error) {
				console.error('Something went wrong while retrieving cost type enum list', error)
			}
		},
        invoiceItemVatPercentageError(invoiceItemIndex) {
			if (!this.$v.invoiceItems.$each[invoiceItemIndex].vatPercentage.$error) return null
			if (!this.$v.invoiceItems.$each[invoiceItemIndex].vatPercentage.between) {
				return vatPercentageMinMaxValue
			}

			return isRequiredErrorMessage('vatPercentage', 'BTW', this.$v.invoiceItems.$each[invoiceItemIndex].vatPercentage)
		},
        invoiceItemPriceBeforeTaxError(invoiceItemIndex) {
			if (!this.$v.invoiceItems.$each[invoiceItemIndex].priceBeforeTax.$error) return null
			if (!this.$v.invoiceItems.$each[invoiceItemIndex].priceBeforeTax.minValue) {
				return priceBeforeTaxMinValue
			}

			return isRequiredErrorMessage('priceBeforeTax', 'Prijs', this.$v.invoiceItems.$each[invoiceItemIndex].priceBeforeTax)
		},
        invoiceItemVatCountryError(invoiceItemIndex) {
			return isRequiredErrorMessage('vatCountry', 'Land', this.$v.invoiceItems.$each[invoiceItemIndex].vatCountry )
		}, 
        onCountryChange(newValue) {
            this.country = newValue

            this.$emit('setCountry',  this.country)
        },
        onVatPercentageChange(newValue) {
            this.vat = Number(newValue)
            this.$emit('setVatPercentage', this.vat)
		},
        onInvoiceItemCostTypeChange(invoiceItemIndex, newValue) {
            this.$emit('setInvoiceItemCostType', invoiceItemIndex, newValue)
			this.$v.invoiceItems?.$each[invoiceItemIndex]?.costType.$touch()
        },
        onInvoiceItemVatPercentageChange(invoiceItemIndex, newValue) {
            this.$emit('setInvoiceItemVatPercentage',  invoiceItemIndex, newValue)
            
            // calculate vatAmount and priceBeforeTax
            this.performCalculations(invoiceItemIndex)

			this.$v.invoiceItems.$each[invoiceItemIndex].vatPercentage.$touch()
        },
        async onInvoiceItemCountryChange(invoiceItemIndex, newValue) {
            const currentInvoiceItem = this.invoiceItems[invoiceItemIndex]
            currentInvoiceItem.vatCountry = newValue

            await this.setCountryAndCountryTaxRates(currentInvoiceItem, true)
            this.$emit('setInvoiceItemCountryAndVatPercentageOptions', invoiceItemIndex, currentInvoiceItem)
			this.$v.invoiceItems.$each[invoiceItemIndex].vatCountry.$touch()
        },
        onPriceBeforeTaxChange(invoiceItemIndex, newValue) {
            this.$emit('setInvoiceItemPriceBeforeTax', invoiceItemIndex, newValue)
            this.$v.invoiceItems.$each[invoiceItemIndex].priceBeforeTax.$touch()

            this.performCalculations(invoiceItemIndex)
        },
        performCalculations(invoiceItemIndex){
            this.$emit('performCalculations', invoiceItemIndex)
            this.currentPricePerPersonWithoutVat = this.invoiceItems.reduce((sum, item) => sum + item.priceBeforeTax, 0)
            this.updatePerPersonOrderCosts()
        },
        updatePerPersonOrderCosts(){
            this.$emit('perPersonOrderCosts',
            { 
                currentPricePerPersonWithoutVat: this.currentPricePerPersonWithoutVat,
                purchaseAgreementPricePerPersonWithoutVat: this.purchaseAgreementPricePerPersonWithoutVat
            })
        }
	}
}
</script>
