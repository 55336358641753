var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"c-modal-tab-navigation"},[_c('div',{staticClass:"d-flex justify-content-start align-items-center u-text-center px-5"},_vm._l((_vm.navigationTabs),function(tab){return _c('div',{key:tab.id,staticClass:"qa-modal-tab-navigation",class:[
                'c-modal-tab-navigation__item', 
                'u-cursor-pointer', 
                {
                'is-active-link': _vm.activeTab === tab.id, 
                'is-disabled': tab.isDisabled,
                'has-errors': tab.hasErrors
                }
            ],on:{"click":function($event){return _vm.selectTab(tab.id)}}},[_c('span',{staticClass:"c-modal-tab-navigation__item-title"},[_vm._v(_vm._s(tab.title))])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }