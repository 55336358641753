<template>
    <div class="c-modal-tab-navigation">
        <div class="d-flex justify-content-start align-items-center u-text-center px-5">
            <div
                v-for="(tab) in navigationTabs"
                :key="tab.id"
                @click="selectTab(tab.id)"
                :class="[
                    'c-modal-tab-navigation__item', 
                    'u-cursor-pointer', 
                    {
                    'is-active-link': activeTab === tab.id, 
                    'is-disabled': tab.isDisabled,
                    'has-errors': tab.hasErrors
                    }
                ]"
                class="qa-modal-tab-navigation"
            >
                <span class="c-modal-tab-navigation__item-title">{{ tab.title }}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ModalTabNavigation',
    props: {
        navigationTabs: {
            type: Array,
            default: null
        },
        activeTab: {
            type: String,
            default: null
        }
    },
    methods: {
        selectTab(id) {
            if(this.activeTab === id) return
            if (!this.navigationTabs.find(tab => tab.id === id).isDisabled) {
                this.$emit('tab-selected', id)
            }
        }
    }
}
</script>