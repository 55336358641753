<template>
    <button v-bind="$attrs" class="c-button" :class="buttonClass" v-on="listeners">
        <span v-if="iconFont" class="material-icons c-button__icon">{{ iconFont }}</span>
        <span v-if="backLabel" class="c-button-icon__text">{{ backLabel }}</span>
        <slot></slot>
    </button>
</template>

<script>
export default {
    name: 'ButtonComponent',
    inheritAttrs: false,
    props: {
        buttonClass: {
            type: String,
            default: null
        },
        iconFont: {
            type: String,
            default: null
        },
        backLabel: {
            type: String,
            default: null
        },
        to: {
            type: [String, Object],
            default: null
        },
        href: {
            type: [String, Object],
            default: ''
        }
    },
    computed: {
        listeners() {
            return {
                click: (event) => this.clickButton(event)
            }
        }
    },
    methods: {
        routerPush() {
            this.$router.push(this.to)
        },
        clickButton(event) {
            this.$emit('click', event)
            /* This directive wrap a $router.push() when a string or object is sent by directive "to". This allows the use of vue router navigation.
              Example 1: string) <icon-link-button to="/about" />
              Example 2: object path) <icon-link-button :to="{ path: '/about' }" />
              */
            if (this.to) {
                this.routerPush()
            }
            /* This enables the button to accept a link when a string or object is sent via "href".
              Example 1: string) <icon-link-button href="https://provider-edums-nl-tst.azurewebsites.net/#/" />
              Example 2: object path) <icon-link-button :href="{url: 'https://provider-edums-nl-tst.azurewebsites.net/#/'}" />
              */
            if (this.href) {
                if (typeof this.href === 'string') {
                    if (this.target) {
                        window.open(this.href)
                    } else {
                        window.location.href = this.href
                    }
                } else if (this.target) {
                    window.open(this.href.url)
                } else {
                    window.location.href = this.href.url
                }
            }
        }
    }
}
</script>